import { MainFeaturesRoutesEnum, VevMainRoute } from '../types/vev-route';
import { Action, Entity } from '../types/Authorization';
import { TenantComponents } from '../types/Tenant';
import { ReleaseNotesComponent } from '../release-notes/release-notes.component';

export const MainFeaturesRoutes: VevMainRoute[] = [
  {
    path: MainFeaturesRoutesEnum.CHARGING_STATION,
    loadChildren: async () =>
      (await import('../pages/charging-stations/charging-stations.module')).ChargingStationsModule,
    data: {
      breadcrumb: 'general.menu.charging_stations',
      menu: {
        title: 'charging_stations',
        icon: 'ev_station',
        path: '/charging-stations',
      },
      auth: {
        entity: Entity.CHARGING_STATION,
        action: Action.LIST,
      },
    },
  },
  {
    path: MainFeaturesRoutesEnum.TRANSACTIONS,
    loadChildren: async () => (await import('../pages/transactions/transactions.module')).TransactionsModule,
    data: {
      menu: {
        title: 'transactions',
        icon: 'history',
        path: '/transactions',
      },
      auth: {
        entity: Entity.TRANSACTION,
        action: Action.LIST,
      },
    },
  },
  {
    path: MainFeaturesRoutesEnum.INVOICE,
    loadChildren: async () => (await import('../pages/invoices/invoices.module')).InvoicesModule,
    data: {
      menu: {
        title: 'invoices',
        icon: 'receipt',
        path: '/invoices',
      },
      auth: {
        entity: Entity.INVOICE,
        action: Action.LIST,
      },
      component: TenantComponents.BILLING,
    },
  },
  {
    path: MainFeaturesRoutesEnum.CARS,
    loadChildren: async () => (await import('../pages/cars/cars.module')).CarsModule,
    data: {
      menu: {
        title: 'cars',
        icon: 'directions_car',
        path: '/cars',
      },
      auth: [
        { entity: Entity.CAR_CATALOG, action: Action.LIST },
        { entity: Entity.CAR, action: Action.LIST },
      ],
      activeInSuperTenant: true,
      displayInSuperTenant: true,
      component: TenantComponents.CAR,
    },
  },
  {
    path: MainFeaturesRoutesEnum.USERS,
    loadChildren: async () => (await import('../pages/users/users.module')).UsersModule,
    data: {
      menu: {
        title: 'users',
        icon: 'people',
        path: '/users',
      },
      auth: {
        entity: Entity.USER,
        action: Action.LIST,
      },
    },
  },
  {
    path: MainFeaturesRoutesEnum.TAGS,
    loadChildren: async () => (await import('../pages/tags/tags.module')).TagsModule,
    data: {
      menu: {
        title: 'tags',
        icon: 'badge',
        path: '/tags',
      },
      auth: {
        entity: Entity.TAG,
        action: Action.LIST,
      },
    },
  },
  {
    path: MainFeaturesRoutesEnum.ASSETS,
    loadChildren: async () => (await import('../pages/assets/assets.module')).AssetsModule,
    data: {
      menu: {
        title: 'assets',
        icon: 'account_balance',
        path: '/assets',
      },
      auth: {
        entity: Entity.ASSET,
        action: Action.LIST,
      },
      component: TenantComponents.ASSET,
    },
  },
  {
    path: MainFeaturesRoutesEnum.ORGANIZATIONS,
    loadChildren: async () => (await import('../pages/organization/organization.module')).OrganizationModule,
    data: {
      menu: {
        title: 'organization',
        icon: 'business',
        path: MainFeaturesRoutesEnum.ORGANIZATIONS,
      },
      auth: [
        { entity: Entity.COMPANY, action: Action.LIST },
        { entity: Entity.SITE, action: Action.LIST },
        { entity: Entity.SITE_AREA, action: Action.LIST },
      ],
    },
  },
  {
    path: MainFeaturesRoutesEnum.STATISTICS,
    loadChildren: async () => (await import('../pages/statistics/statistics.module')).StatisticsModule,
    data: {
      menu: {
        title: 'statistics',
        icon: 'assessment',
        path: '/statistics',
      },
      auth: {
        entity: Entity.TRANSACTION,
        action: Action.LIST,
      },
      component: TenantComponents.STATISTICS,
    },
  },
  {
    path: MainFeaturesRoutesEnum.SETTINGS,
    loadChildren: async () => (await import('../pages/settings/settings.module')).SettingsModule,
    data: {
      breadcrumb: 'general.menu.settings',
      menu: {
        title: 'settings',
        icon: 'settings',
        path: '/settings',
      },
      auth: {
        entity: Entity.SETTING,
        action: Action.LIST,
      },
    },
  },
  {
    path: MainFeaturesRoutesEnum.LOGS,
    loadChildren: async () => (await import('../pages/logs/logs.module')).LogsModule,
    data: {
      menu: {
        title: 'logs',
        icon: 'list',
        path: '/logs',
      },
      auth: {
        entity: Entity.LOGGING,
        action: Action.LIST,
      },
    },
  },
  {
    path: MainFeaturesRoutesEnum.RELEASE_NOTES,
    component: ReleaseNotesComponent,
  },
  {
    path: '',
    redirectTo: MainFeaturesRoutesEnum.CHARGING_STATION,
    pathMatch: 'full',
  },
];
