import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusCodes } from 'http-status-codes';
import { MessageService } from 'services/message.service';
import { Utils } from 'utils/Utils';
import 'bootstrap';
import { NavigationService } from '@services/navigation/navigation.service';
import { Constants } from '@utils/Constants';
import { ComponentService } from '@services/component.service';
import { AuthorizationService } from '@services/authorization.service';
import { CentralServerService } from '@services/central-server.service';
import { TenantRepository } from '@repository/tenant/tenant.repository';
import { SpinnerService } from '@services/spinner.service';
import { finalize } from 'rxjs';
import { UserToken } from '../types/User';

@Component({
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss'],
})
export class SidebarComponent {
  public mobileMenuVisible: any = 0;
  public menuItems!: any[];
  public loggedUser!: UserToken;
  public loggedUserImage = Constants.USER_NO_PICTURE;
  public isAdmin = false;
  public isSiteAdmin = false;
  public canEditProfile = false;
  public logo = Constants.NO_IMAGE;
  public sidebarMinimized: boolean;

  public constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authorizationService: AuthorizationService,
    private centralServerService: CentralServerService,
    private messageService: MessageService,
    private readonly navigationService: NavigationService,
    private readonly componentService: ComponentService,
    private readonly spinnerService: SpinnerService,
    private readonly tenantRepository: TenantRepository,
    @Inject(DOCUMENT) private document: Document,
  ) {
    // Get the routes
    if (this.activatedRoute && this.activatedRoute.routeConfig && this.activatedRoute.routeConfig.children) {
      this.menuItems = this.activatedRoute.routeConfig.children;
      this.menuItems = this.activatedRoute.routeConfig.children
        // .filter(
        //   (route) =>
        //     (route.data?.component ? this.componentService.isActive(route.data?.component) : true) ||
        //     (this.authorizationService.isSuperAdmin() && route.data?.activeInSuperTenant),
        // )
        .filter(
          (route) =>
            (route.data?.auth &&
              this.navigationService.isRouteAllowed(route.data.auth) &&
              (route.data?.component ? this.componentService.isActive(route.data?.component) : true)) ||
            (this.authorizationService.isSuperAdmin() && route.data?.activeInSuperTenant),
        )
        .map((route) => (route && route.data ? route.data.menu : null));
    }
    // Set admin
    this.isAdmin = this.authorizationService.isAdmin() || this.authorizationService.isSuperAdmin();
    this.isSiteAdmin = this.authorizationService.getSitesAdmin().length > 0;
    // Get the logged user
    this.centralServerService.getCurrentUserSubject().subscribe((user) => {
      this.loggedUser = user;
    });
    if (authorizationService.canUpdateUser()) {
      this.canEditProfile = true;
    }

    // Read user
    this.loadUser();
    // Read tenant
    this.loadTenant();
  }

  public toggleSidebar(): void {
    const body = this.document.getElementsByTagName('body')[0];
    if (this.sidebarMinimized === true) {
      body.classList.remove('sidebar-mini');
      this.sidebarMinimized = false;
    } else {
      body.classList.add('sidebar-mini');
      this.sidebarMinimized = true;
    }
  }

  public logout(): void {
    this.spinnerService.show();
    this.centralServerService
      .logout()
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        }),
      )
      .subscribe();
  }

  private loadTenant(): void {
    // Get Tenant logo
    this.tenantRepository.getTenantLogo().subscribe({
      next: (tenantLogo) => {
        this.logo = tenantLogo;
      },
    });
  }
  private loadUser(): void {
    // Get the user's image
    if (this.loggedUser && this.loggedUser.id) {
      this.centralServerService.getUserImage(this.loggedUser.id).subscribe({
        next: (userImage) => {
          this.loggedUserImage = userImage ?? Constants.USER_NO_PICTURE;
        },
        error: (error) => {
          switch (error.status) {
            case StatusCodes.NOT_FOUND:
              this.loggedUserImage = Constants.USER_NO_PICTURE;
              break;
            default:
              Utils.handleHttpError(
                error,
                this.router,
                this.messageService,
                this.centralServerService,
                'general.unexpected_error_backend',
              );
          }
        },
      });
    }
  }
}
