import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthConfig } from '@auth0/auth0-angular';
import { ConfigEntity } from '@repository/config/entities/config.entity';
import { EnvironmentApiService } from '@api/config/environment-api.service';
import { WINDOW } from '@providers/window.provider';
import { Configuration } from '../../types/configuration/Configuration';
import { LoginComponent } from '../../pages/authentication/login/login.component';
import { ApiUrlEntity } from './entities/api-url.entity';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentRepositoryService {
  public apiUrl!: ApiUrlEntity;

  public constructor(
    private envApi: EnvironmentApiService,
    @Inject(WINDOW) private window: Window,
  ) {}

  public getConfig(): Observable<Configuration> {
    return this.envApi.getConfig();
  }

  public buildApiUrlEntity(config: Configuration): ApiUrlEntity {
    const baseUrl = this.getBaseUrl(config);
    return {
      baseUrl,
      authUrl: `${baseUrl}/v1/auth/`,
      apiUrl: `${baseUrl}/v1/api/`,
      apiUrlV2: `${baseUrl}/v2/api/`,
      apiUtilUrl: `${baseUrl}/v1/util/`,
      cardConfigUrl: config.carDatabaseUrl,
      captchaKey: config.captchaApiKey,
      mixpanelApiKey: config.mixpanelApiKey,
      assetsUrl: `https://${config.assetsUrl}`,
    };
  }

  public buildAuthConfig(config: Configuration): AuthConfig {
    const auth0Config = config.auth0;
    return {
      domain: auth0Config.domain,
      clientId: auth0Config.clientId,
      authorizationParams: {
        scope: 'openid profile email',
        redirect_uri: `${this.window.location.origin}/auth/${LoginComponent.SSO_CALLBACK_PATH}`,
      },
    };
  }

  public getConfigEntity(): Observable<ConfigEntity> {
    return this.getConfig().pipe(
      map((config) => {
        return {
          apiUrlEntity: this.buildApiUrlEntity(config),
          auth0Config: this.buildAuthConfig(config),
        };
      }),
    );
  }

  private getBaseUrl(config: Configuration): string {
    const apiConfiguration = config.api;
    const protocol = apiConfiguration?.protocol || this.window.location.protocol.slice(0, -1);
    const host = apiConfiguration?.host || this.window.location.hostname;
    const port = apiConfiguration?.port;

    const baseUrl = `${protocol}://${host}`;
    if (port) {
      return `${baseUrl}:${port}`;
    }

    return baseUrl;
  }
}
