import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../../types/configuration/Configuration';
import { environment } from '../../../environments/environment';

@Injectable()
export class EnvironmentApiService {
  public constructor(private httpClient: HttpClient) {}

  public getConfig(): Observable<Configuration> {
    console.log(environment.buildId);
    return this.httpClient.get<Configuration>('/assets/config.json', { params: { buildId: environment.buildId } });
  }
}
